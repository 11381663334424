<!--
 * @Author: DY
 * @Date: 2021-11-24 12:08:17
 * @LastEditTime: 2021-12-04 15:46:10
 * @Description:
 * @Param:
 * @FilePath: \JT_Web_test\src\views\EBCHome\dailyData\index.vue
-->
<template>
    <div class="dailyData">
        <div class="header">
            <span>每日数据</span>
            <div class="header_btns">
                <div class="date">
                    <p>{{ currentDate }}</p>
                    <i class="el-icon-date"></i>
                    <el-date-picker
                        type="date"
                        align="right"
                        v-model="currentDate"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </div>
                <back></back>
            </div>
        </div>
        <div
            class="content"
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
        >
            <div class="statisticalArea">
                <div class="enterpriseSite">
                    <p>
                        商混企业：
                        <span v-if="enterpriseStationData.company_list">
                            {{ enterpriseStationData.company_list.length }}
                        </span>
                        <span v-else>0</span>
                        个
                    </p>
                    <p>
                        搅拌站：
                        <span v-if="enterpriseStationData.station_list">
                            {{ enterpriseStationData.station_list.length }}
                        </span>
                        <span v-else>0</span>
                        个
                    </p>
                </div>
                <div class="screen">
                    <button class="iconfont iconshaixuan">
                        筛选
                    </button>
                    <span></span>
                </div>
                <div class="constructionSite">
                    <div>
                        <i></i>
                        <div>
                            <h4>生产站点</h4>
                            <p><span>{{ productionStatisticsData.sczd }}</span>个</p>
                        </div>
                    </div>
                    <div>
                        <i></i>
                        <div>
                            <h4>服务工地</h4>
                            <p><span>{{ productionStatisticsData.fwgd }}</span>个</p>
                        </div>
                    </div>
                </div>
                <div class="productionProcurement">
                    <div class="btns">
                        <template v-for="(item, index) in navData">
                            <button
                                :key="`nav_btn_${index}`"
                                class="iconfont iconjiaobanzhan"
                                :class="{'active': navIndex === index}"
                                @click="navSwitch(index)"
                            >
                                {{ item.name }}
                            </button>
                        </template>
                    </div>
                    <ul class="classification">
                        <template v-for="(item, index) in showSubsetData">
                            <li
                                :key="`subset_${index}`"
                                :class="{'active': subsetIndex === index}"
                                @click="subsetSwitch(item, index, $event)"
                            >
                                <i class="iconfont" :class="item.icon"></i>
                                <div>
                                    <h4>{{ item.name }}</h4>
                                    <p>
                                        <span>{{ subsetListData[item.type] }}</span>
                                        {{ item.company }}
                                    </p>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="charts" id="pieChart"></div>
            <mapContent ref="mapDom"></mapContent>
        </div>
    </div>
</template>

<script>
import back from './../back';
import mapContent from './gaodeMap.vue';
import util from '@/utils/util';

export default {
    name: 'daily-data',
    components: { back, mapContent },
    props: {},
    data() {
        return {
            loading: false,
            currentDate: util.currentTimeFormat('yyyy-MM-dd'),
            navData: [
                {
                    name: '生产',
                    type: 1,
                    subsetData: [{
                        name: '供料任务',
                        type: 'glrw',
                        icon: 'iconorder',
                        company: '个',
                    }, {
                        name: '任务方量',
                        type: 'rwfl',
                        icon: 'icond',
                        company: '方',
                    }, {
                        name: '发货方量',
                        type: 'fhfl',
                        icon: 'iconfahuofangliang',
                        company: '方',
                    }, {
                        name: '完成方量',
                        type: 'wcfl',
                        icon: 'iconwanchengfangliang',
                        company: '方',
                    }, {
                        name: '完成率',
                        type: 'wcl',
                        icon: 'iconshujutubiaobingtu-',
                        company: '%',
                    }, {
                        name: '供料车次',
                        type: 'glcc',
                        icon: 'iconjiaobanche',
                        company: '车',
                    }],
                },
                {
                    name: '采购',
                    type: 2,
                    subsetData: [{
                        name: '原材料采购量',
                        type: 'supplied_count',
                        icon: 'iconcaigou',
                    }, {
                        name: '原材料运输车次',
                        type: 'transport_count',
                        icon: 'iconcishu1',
                    }],
                },
            ],
            navIndex: 0,
            showSubsetData: [],
            subsetIndex: null,
            subsetListData: {},
            // 企业场站数据
            enterpriseStationData: {},
            // 生产统计数据
            productionStatisticsData: {},
            // 采购统计数据
            purchaseStatistics: {},
            chartPosition: '',
            chartData: {
                formatter: '',
                tooltipFormatter: '',
                data: [],
            },
        };
    },
    computed: {},
    watch: {
        currentDate() {
            this.subsetIndex = null;
            this.$('.charts').fadeOut();
            this.loadPage();
        },
    },
    created() {
        this.showSubsetData = this.navData[0].subsetData;
    },
    async mounted() {
        this.loading = true;
        this.enterpriseStationData = await this.getEnterpriseStationData();
        // 生产统计数据
        this.productionStatisticsData = await this.getProductionStatisticsData();
        this.subsetListData = this.productionStatisticsData;
        // 采购统计数据
        this.purchaseStatistics = await this.getPurchaseStatisticsData();
        await this.$refs.mapDom.initPage();
    },
    destroyed() {},
    methods: {
        async loadPage() {
            this.loading = true;
            // 生产统计数据
            this.productionStatisticsData = await this.getProductionStatisticsData();
            this.subsetListData = this.productionStatisticsData;
            // 采购统计数据
            this.purchaseStatistics = await this.getPurchaseStatisticsData();
            this.$refs.mapDom.loadMarkers();
            this.loading = false;
        },
        navSwitch(index) {
            this.navIndex = index;
            this.subsetIndex = null;
            this.$('.charts').fadeOut();
            this.showSubsetData = this.navData[index].subsetData;
            if (index === 0) {
                this.subsetListData = this.productionStatisticsData;
            } else {
                this.subsetListData = this.purchaseStatistics;
            }
        },
        subsetSwitch(item, index, event) {
            this.chartData.formatter = '';
            this.chartData.tooltipFormatter = '';
            this.chartData.data = [];
            if (this.subsetIndex === index) {
                this.subsetIndex = null;
                this.$('.charts').fadeOut();
                return;
            }
            this.subsetIndex = index;
            if (index > 2) {
                const deviation = this.$('.charts').height() - this.$(event.currentTarget).height() - 10;
                this.chartPosition = this.$(event.currentTarget).offset().top - deviation;
            } else {
                this.chartPosition = this.$(event.currentTarget).offset().top;
            }
            let chartData = null;
            if (this.navIndex === 0) {
                if (item.type === 'wcl') {
                    this.chartData.formatter = '{a|{b} ({c}%)}';
                    this.chartData.tooltipFormatter = '{b} : {c}%';
                    chartData = this.subsetListData.pie_charts_wcl;
                } else if (this.subsetListData[item.type]) {
                    this.chartData.formatter = '{a|{b}：}{b|{c}}';
                    this.chartData.tooltipFormatter = '{b} : {d}% <br/> {c}';
                    chartData = this.subsetListData.pie_charts[item.type];
                }
            } else {
                return;
            }
            if ((chartData || []).length > 0) {
                this.$('.charts').fadeIn().animate({
                    top: this.chartPosition + 'px',
                });
                this.$nextTick(async () => {
                    this.processingChartData(item, chartData);
                });
            } else {
                this.$('.charts').fadeOut();
                this.$message({
                    message: '暂无数据',
                    type: 'warning',
                });
            }
        },
        // 处理图表数据
        processingChartData(item, res) {
            res.forEach(k => {
                let neVal = '';
                if (item.type === 'wcl') {
                    neVal = k.wcl;
                } else {
                    neVal = k.value;
                }
                this.chartData.data.push({
                    name: k.name,
                    value: neVal,
                });
            });
            this.ringDiagram('pieChart', this.chartData);
        },
        getEnterpriseStationData() {
            const path = '/interfaceApi/report/groupeverydayanalysis/proc/getorgdata';
            return this.$axios.$get(path, { defEx: true });
        },
        getProductionStatisticsData() {
            const path = '/interfaceApi/report/groupeverydayanalysis/prod/gettotal';
            return this.$axios.$post(path, {
                statistics_date: this.currentDate,
            }, { defEx: true });
        },
        getPurchaseStatisticsData() {
            const path = '/interfaceApi/report/groupeverydayanalysis/proc/procurement/gettotal';
            return this.$axios.$post(path, {
                statistics_date: this.currentDate,
            }, { defEx: true });
        },

        ringDiagram(value, data) {
            const ringDiagramChart = window.$globalHub.$echarts.init(document.getElementById(value));
            const colorList = [
                '#2D809E', '#3799BC', '#58B5DB', '#79D2F6', '#66FDBF', '#5EDCA9',
                '#17A277', '#04865B', '#036D42', '#9E87FF', '#58D5FF', '#73ACFF',
                '#FDD56A', '#FDB36A', '#FD866A', '#9E87FF',
            ];
            const option = {
                title: {
                    text: data.title,
                    x: 'center',
                    y: 'center',
                    textStyle: {
                        color: '#78D2F6',
                        fontSize: '20',
                    },
                },
                tooltip: {
                    trigger: 'item',
                    formatter: data.tooltipFormatter,
                },
                graphic: {
                    elements: [{
                        type: 'image',
                        style: {
                            width: 100,
                            height: 100,
                        },
                        left: 'center',
                        top: 'center',
                    }],
                },
                series: [{
                    type: 'pie',
                    center: ['50%', '50%'],
                    radius: ['43%', '60%'],
                    minAngle: 10,
                    avoidLabelOverlap: true,
                    hoverOffset: 15,
                    itemStyle: {
                        color: params => {
                            return colorList[params.dataIndex];
                        },
                    },
                    label: {
                        show: true,
                        position: 'outer',
                        alignTo: 'labelLine',
                        backgroundColor: 'auto',
                        height: 0,
                        width: 0,
                        lineHeight: 0,
                        distanceToLabelLine: 0,
                        borderRadius: 2.5,
                        padding: [2.5, -2.5, 2.5, -2.5],
                        formatter: data.formatter,
                        rich: {
                            a: {
                                padding: [0, 0, 0, 10],
                            },
                            b: {
                                padding: [0, 10, 0, 0],
                            },
                        },
                    },
                    data: data.data,
                }],
            };
            if (data.data.length < 1) {
                option.title.textStyle.color = 'rgba(255,255,255,0.3)';
                option.title.textStyle.fontSize = '50';
            }
            ringDiagramChart.setOption(option);
        },
    },
};
</script>
<style lang="stylus" scoped>
.dailyData
    width 100%
    height: 100%
    background #0B204B
    position: relative
    .header
        width 100%
        height 0.7rem
        line-height: 0.7rem
        padding-left 0.4rem
        background: rgba(14, 42, 82, 0.8)
        border-bottom 1px solid #1B3458
        display: flex
        align-items center
        justify-content space-between
        position: absolute
        left 0
        top 0
        z-index 9
        span
            font-size 0.34rem
            font-family youSheBiaoTiHei
            color rgba(255, 255, 255, 1)
            background linear-gradient(180deg, #FFFFFF 0%, #6C97D8 100%);
            -webkit-background-clip text
            -webkit-text-fill-color transparent
        .header_btns
            display: flex
            .date
                font-size 0.28rem
                line-height: 0.7rem
                color #fff
                display flex
                align-items center
                position: relative
                p
                    margin-right: 0.1rem
                    font-family agencyb
                .el-date-editor
                    background-color: darkblue !important;
                    width: auto !important;
                    position: absolute !important;
                    top: 0.12rem !important;
                    left: 0 !important;
                    opacity: 0;
            .back
                width 0.7rem
                height: 0.7rem
                line-height: 0.7rem
                position: inherit
                text-align: center
    .content
        height 100%
        position: relative
        .statisticalArea
            width 5.3rem
            position absolute
            top 0.8rem
            left 0.4rem
            z-index 9
            .enterpriseSite
                display: flex
                height: 0.8rem
                font-size 0.36rem
                font-family youSheBiaoTiHei
                background linear-gradient(180deg, #FFFFFF 0%, #6C97D8 100%);
                -webkit-background-clip text
                -webkit-text-fill-color transparent
                justify-content space-between
                p
                    span
                        font-size 0.62rem
                        font-family agencyb
                        background linear-gradient(180deg, #FFFFFF 0%, #6DD400 100%);
                        -webkit-background-clip text
                        -webkit-text-fill-color transparent
                        justify-content space-between
                    &:last-child
                        span
                            background linear-gradient(180deg, #FFFFFF 0%, #FA9900 100%);
                            -webkit-background-clip text
                            -webkit-text-fill-color transparent
                            justify-content space-between
            .screen
                display: flex
                height: 0.4rem
                button
                    width 0.94rem
                    background: #0091FF
                    border 1px solid #1B3458
                    color #fff
                    font-size 0.18rem
                    cursor pointer
                >span
                    width calc(100% - 0.96rem)
                    margin-left: 0.02rem
                    background: url(images/bg1.png) no-repeat
                    background-size: 100% 100%
            .constructionSite
                height: 1.2rem
                background: url(images/bg3.png) no-repeat
                background-size: 100% 100%
                margin-top: 0.04rem
                display: flex
                >div
                    margin-left 0.5rem
                    padding-top: 0.1rem
                    display: flex
                    flex 1
                    background: url(images/bg8_normal.png) left 91% no-repeat
                    background-size: 1.8rem
                    i
                        width 0.6rem
                        height: 0.6rem
                        margin: 0.1rem 0.1rem 0 0
                        background: url(images/bg7_normal.png) no-repeat
                        background-size: 100%
                    h4
                        font-size 0.32rem
                        line-height: 0.34rem
                        font-family youSheBiaoTiHei
                        background linear-gradient(180deg, #A0B8E5 0%, #FFFFFF 50%, #A0B8E5 100%);
                        -webkit-background-clip text
                        -webkit-text-fill-color transparent
                        justify-content space-between
                    p
                        font-size 0.18rem
                        height 0.5rem
                        line-height: 0.5rem
                        margin-top: 0.05rem
                        color #8BA5B8
                        span
                            margin-right: 0.1rem
                            font-size 0.46rem
                            font-family agencyb
                            background linear-gradient(180deg, #D1F48D 0%, #F5C24C 100%);
                            -webkit-background-clip text
                            -webkit-text-fill-color transparent
                            justify-content space-between
                    &:last-child
                        p
                            span
                                background linear-gradient(180deg, #7BE9CB 0%, #00B59B 100%);
                                -webkit-background-clip text
                                -webkit-text-fill-color transparent
                                justify-content space-between
            .productionProcurement
                width 3.6rem
                height: 5.7rem
                background: url(images/bg4.png) no-repeat
                background-size: 100% 100%
                margin-top: 0.04rem
                padding: 0.2rem
                .btns
                    padding: 0 0.2rem
                    height: 0.36rem
                    display: flex
                    align-items center
                    justify-content space-between
                    margin-bottom: 0.2rem
                    button
                        width 1.2rem
                        height: 0.36rem
                        border: none
                        background: url(images/bg5_normal.png) no-repeat
                        background-size: 100% 100%
                        font-size 0.18rem
                        color #ABD0F8
                        text-align: left
                        text-indent: 0.18rem
                        cursor pointer
                        &.active
                            background: url(images/bg5_highlight.png) no-repeat
                            background-size: 100% 100%
                            color #32C5FF
                .classification
                    li
                        width 3rem
                        height: 0.7rem
                        margin-bottom: 0.1rem
                        background: url(images/bg6_normal.png) no-repeat
                        background-size: 100% 100%
                        display: flex
                        cursor pointer
                        i
                            width 0.7rem
                            height: 0.7rem
                            font-size 0.24rem
                            text-align: center
                            line-height: 0.7rem
                            background linear-gradient(180deg, #05DEBF 0%, #04A2DC 100%);
                            -webkit-background-clip text
                            -webkit-text-fill-color transparent
                            justify-content space-between
                        div
                            margin-left: 0.2rem
                            padding-top: 0.05rem
                            h4
                                font-size 0.22rem
                                line-height: 0.3rem
                                font-family youSheBiaoTiHei
                                background linear-gradient(180deg, #A0B8E5 0%, #FFFFFF 50%, #A0B8E5 100%);
                                -webkit-background-clip text
                                -webkit-text-fill-color transparent
                                justify-content space-between
                            p
                                height 0.3rem
                                font-size 0.16rem
                                line-height: 0.3rem
                                color #8BA5B8
                                span
                                    font-size 0.28rem
                                    margin-right: 0.1rem
                                    font-family agencyb
                                    background linear-gradient(180deg, #05DCC0 0%, #04A2DC 100%);
                                    -webkit-background-clip text
                                    -webkit-text-fill-color transparent
                                    justify-content space-between
                        &.active
                            background: url(images/bg6_highlight.png) no-repeat
                            background-size: 100% 100%
                            i
                                background linear-gradient(180deg, #fa9f0f 0%, #fa9f0f 100%);
                                -webkit-background-clip text
                                -webkit-text-fill-color transparent
                                justify-content space-between
                            div
                                p
                                    span
                                        background linear-gradient(180deg, #C3F366 0%, #F5C24C 100%);
                                        -webkit-background-clip text
                                        -webkit-text-fill-color transparent
                                        justify-content space-between
        .charts
            width 5.35rem
            height: 2.72rem
            background: url(images/bg2.png) no-repeat
            background-size: 100% 100%
            position absolute
            top 5rem
            left 4.1rem
            z-index 9
            display none
</style>